/*
 * Licensed under the EUPL, Version 1.2 or – as soon they will be approved by
 * the European Commission - subsequent versions of the EUPL (the "Licence");
 * You may not use this work except in compliance with the Licence.
 * You may obtain a copy of the Licence at:
 *
 *   https://joinup.ec.europa.eu/software/page/eupl
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the Licence is distributed on an "AS IS" basis,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the Licence for the specific language governing permissions and
 * limitations under the Licence.
 *
 */

.dropzone {
  max-width: 95%;
  border: 1px dashed #666;
  border-radius: 2px;
  margin: 20px auto;
  padding: 1rem;
  cursor: pointer;
}

.filelist {
  max-width: 95%;
  min-width: 95%;
  margin: auto;
  min-height: 300px;
  max-height: 300px;
}

.file-select {
  min-width: 100%;
  min-height: 300px;
}

.dropZone--active {
  border-color: green;
}

.dropZone--reject {
  border-color: red;
  animation: shake 0.5s ease-in-out;
  -webkit-animation: shake 0.5s ease-in-out;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(-10px);
  }

  40% {
    transform: translateX(10px);
  }

  60% {
    transform: translateX(-10px);
  }

  80% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
}
