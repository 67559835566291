@import "@entur/icons/dist/styles.css";
@import "@entur/button/dist/styles.css";
@import "@entur/loader/dist/styles.css";
@import "@entur/typography/dist/styles.css";
@import "@entur/menu/dist/styles.css";
@import "@entur/a11y/dist/styles.css";
@import "@entur/expand/dist/styles.css";
@import "@entur/layout/dist/styles.css";
@import "@entur/dropdown/dist/styles.css";
@import "@entur/chip/dist/styles.css";
@import "@entur/form/dist/styles.css";
@import "@entur/tooltip/dist/styles.css";
@import "@entur/tokens/dist/styles.css";
@import "@entur/loader/dist/styles.css";
@import "@entur/modal/dist/styles.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
