.period-block:after {
  content: "";
  height: 18px;
  display: inline-block;
  vertical-align: middle;
}

.page-link-parent {
  display: block;
  margin-bottom: 20px;
}

.page-link {
  display: inline-block;
  cursor: pointer;
  margin-left: 5px;
  margin-top: 3px;
  font-size: 0.9em;
}

.active-link {
  color: #0d3c61;
  text-decoration: underline;
}

.inactive-link {
  color: #2196f3;
}

a {
  color: #2196f3;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #1976d2;
}
